import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  keyboardClear (event) {
    if (event.code === "Escape") this.clear()
  }

  backgroundClear (event) {
    if (!this.modalTarget.contains(event.target)) this.clear()
  }

  clear () {
    this.element.remove()
  }
}
