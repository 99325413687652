import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    url: String,
    placeholder: String,
    maxItems: Number,
    customValues: Array,
    searchField: String,
    labelField: String,
    valueField: String
  }

  connect () {
    this.initTomSelect()
  }

  disconnect () {
    if (this.select) this.select.destroy()
  }

  initTomSelect () {
    const that = this
    const options = {
      plugins: ["remove_button"],
      maxItems: this.hasMaxItemsValue ? this.maxItemsValue : null,
      placeholder: this.hasPlaceholderValue ? this.placeholderValue : "Select...",
      openOnFocus: true,
      persist: false
    }

    if (this.hasCustomValuesValue) {
      options["valueField"] = this.valueFieldValue
      options["labelField"] = this.labelFieldValue
      options["searchField"] = this.searchFieldValue
      // options["options"] = this.customValuesValue
    }

    this.select = new TomSelect(this.element, options)
    
    this.select.on("item_add", (event) => {
      if (this.select.currentResults.query) {
        that.select.refreshItems()
      }
    })
  }
}
