import { Controller } from "@hotwired/stimulus"
import noUiSlider from "nouislider"

export default class extends Controller {
  static targets = ["slider", "input"]
  static values = {
    values: { type: Array, default: [0, 10] },
    range: { type: Array, default: [0, 10] },
    unit: { type: String, default: "" }
  }

  connect () {
    const that = this

    this.slider = noUiSlider.create(this.sliderTarget, {
      start: that.valuesValue,
      step: 1,
      tooltips: {
        to: value => `${value} ${that.unitValue}`,
        from: value => Number(value.replace(` ${that.unitValue}`, ""))
      },
      connect: true,
      range: {
        min: that.rangeValue[0],
        max: that.rangeValue[1]
      }
    })

    this.slider.on("set", event => {
      that.inputTargets.forEach((target, index) => {
        target.value = event[index]
      })
    })
  }
}
