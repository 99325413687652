import confetti from "canvas-confetti"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    onMount: Boolean
  }

  connect () {
    this.canvas = document.getElementById("confetti-canvas")
    if (!!this.onMountValue) this.perform()
  }

  fire (particleRatio, opts) {
    const count = 400
    const defaults = {
      origin: { y: 0.9 }
    }
    this.canvas.confetti = this.canvas.confetti || confetti.create(this.canvas, { resize: true })

    this.canvas.confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      })
    )

    // confetti()
  }

  perform () {
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55
    })
    this.fire(0.2, {
      spread: 60
    })
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }
}
