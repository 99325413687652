import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect () {
    this.disableableElement = document.querySelector(".disableable")
    this.check()
  }

  check () {
    let els = this.checkboxTargets.filter(el => el.checked)
    this.toggleDisabled(!!els.length)
  }

  toggleDisabled (state) {
    this.disableableElement.classList.toggle("pointer-events-none", !state)
    this.disableableElement.classList.toggle("opacity-50", !state)
    this.disableableElement.classList.toggle("cursor-not-allowed", !state)
  }
}
