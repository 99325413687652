import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapsable"]
  static values = {
    collapsed: Boolean
  }

  connect () {
    if (!!this.collapsedValue) this.collapsableTarget.classList.toggle("hidden")
  }

  toggle (event) {
    event.currentTarget.classList.toggle("rotate-180")
    this.collapsableTarget.classList.toggle("hidden")
  }
}
