import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {}

  perform(event) {
    if (this.hasUrlValue) window.open(this.urlValue, "_blank")
  }
}
