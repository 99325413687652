import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "checkbox"]

  connect() {
    this.disableableElement = document.querySelector(".disableable")
    this.validate()
  }

  validate() {
    let inputs = this.inputTargets.filter(el => !!el.value)
    let checkboxes = this.checkboxTargets.filter(el => el.checked)

    this.toggleDisabled(inputs.length == this.inputTargets.length && !!checkboxes.length)
  }

  toggleDisabled(state) {
    this.disableableElement.classList.toggle("pointer-events-none", !state)
    this.disableableElement.classList.toggle("opacity-50", !state)
    this.disableableElement.classList.toggle("cursor-not-allowed", !state)
  }
}
