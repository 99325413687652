import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"

export default class extends Controller {
  static targets = ["input"]
  
  connect () {
    intlTelInput(this.inputTarget, {
      autoPlaceholder: "polite",
      separateDialCode: true,
      hiddenInput: "phone_number_full",
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js"
    })
  }
}
