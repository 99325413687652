import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["disableable"]

  connect () {
    const that = this

    setTimeout(() => {
      that.disableableTarget.classList.remove("pointer-events-none")
      that.disableableTarget.classList.remove("opacity-50")
      that.disableableTarget.classList.remove("cursor-not-allowed")
    }, 5000)
  }
}
